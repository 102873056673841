// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-overview-blog-post-page-tsx": () => import("/home/vsts/work/1/s/src/components/BlogOverview/BlogPostPage.tsx" /* webpackChunkName: "component---src-components-blog-overview-blog-post-page-tsx" */),
  "component---src-components-blog-list-blog-page-tsx": () => import("/home/vsts/work/1/s/src/components/BlogList/BlogPage.tsx" /* webpackChunkName: "component---src-components-blog-list-blog-page-tsx" */),
  "component---src-components-blog-list-blog-category-page-tsx": () => import("/home/vsts/work/1/s/src/components/BlogList/BlogCategoryPage.tsx" /* webpackChunkName: "component---src-components-blog-list-blog-category-page-tsx" */),
  "component---src-pages-404-js": () => import("/home/vsts/work/1/s/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("/home/vsts/work/1/s/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-apps-tsx": () => import("/home/vsts/work/1/s/src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-apps-google-drive-tsx": () => import("/home/vsts/work/1/s/src/pages/apps/google-drive.tsx" /* webpackChunkName: "component---src-pages-apps-google-drive-tsx" */),
  "component---src-pages-home-discover-tsx": () => import("/home/vsts/work/1/s/src/pages/home/discover.tsx" /* webpackChunkName: "component---src-pages-home-discover-tsx" */),
  "component---src-pages-home-home-tsx": () => import("/home/vsts/work/1/s/src/pages/home/home.tsx" /* webpackChunkName: "component---src-pages-home-home-tsx" */),
  "component---src-pages-home-pricing-tsx": () => import("/home/vsts/work/1/s/src/pages/home/pricing.tsx" /* webpackChunkName: "component---src-pages-home-pricing-tsx" */),
  "component---src-pages-home-splash-tsx": () => import("/home/vsts/work/1/s/src/pages/home/splash.tsx" /* webpackChunkName: "component---src-pages-home-splash-tsx" */),
  "component---src-pages-home-testimonials-tsx": () => import("/home/vsts/work/1/s/src/pages/home/testimonials.tsx" /* webpackChunkName: "component---src-pages-home-testimonials-tsx" */),
  "component---src-pages-index-js": () => import("/home/vsts/work/1/s/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-plans-tsx": () => import("/home/vsts/work/1/s/src/pages/pricing-plans.tsx" /* webpackChunkName: "component---src-pages-pricing-plans-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/home/vsts/work/1/s/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-use-cases-tsx": () => import("/home/vsts/work/1/s/src/pages/use-cases.tsx" /* webpackChunkName: "component---src-pages-use-cases-tsx" */)
}

